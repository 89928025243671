<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Contact Group - {{ isNewContactGroup ? "Create" : "Update" }}</span>
        <button class="delete" aria-label="close" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Contact Group Name</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Text input"
              v-model="tempContactGroup.Name"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Contact Group Type</label>
          <div class="control">
            <div class="select">
              <select v-model="tempContactGroup.GroupType" :disabled="fixedGroupType !== undefined">
                <option :value="0">Fulfilment Bureau</option>
                <option :value="1">Customer Service</option>
              </select>
            </div>
          </div>
        </div>

        <h5>Contacts</h5>

        <table class="table is-size-6 is-fullwidth">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Telephone</th>
            <th>Role</th>
            <th></th>
          </tr>
          <tr v-for="d in tempContactGroup.Contacts" :key="d.Id">
            <td>
              <input class="input" type="text" placeholder="Full Name" v-model="d.Name" />
            </td>
            <td>
              <input class="input" type="text" placeholder="Email address" v-model="d.Email" />
            </td>
            <td>
              <input class="input" type="text" placeholder="Telephone number" v-model="d.Telephone" />
            </td>
            <td>
              <input class="input" type="text" placeholder="Role in company" v-model="d.Role" />
            </td>
            <td>
              <button class="button is-danger is-small" v-on:click="removeContact(d)">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="5" style="text-align:center">
              <button class="button is-success is-small" v-on:click="addContact">Add Contact</button>
            </td>
          </tr>
        </table>

        <details class="is-size-6">
          <summary>Quick contact add</summary>
          <p>
            <span>
              You can create multiple contacts easily by pasting into this
              textbox a table of 4 columns (name, email, telephone, role) copied
              from excel
            </span>
            <br />
            <textarea
              class="input"
              type="text"
              @paste.prevent="onPasteContacts"
              style="height:35px;"
            ></textarea>
          </p>
        </details>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-success" :loading="isLoading" v-on:click="saveChanges">Save</b-button>
        <button class="button" v-on:click="close">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "contactGroupInsertOrUpdate",
  mixins: [baseMixin],
  props: {
    contactGroup: null,
    fixedGroupType: null
  },
  data() {
    return {
      newContactId: -1,
      isLoading: false,
      tempContactGroup: this.contactGroup,
      APIUrl: process.env.VUE_APP_APIURL
    };
  },
  created() {
    // var url = this.apiUrlGetBatchJobById(this.batchJobId);
    // this.axios.get(url).then(response => {
    //   this.Fulfilment = response.data;
    // });

    if (this.tempContactGroup.Contacts.length === 0) this.addContact();
  },
  methods: {
    close() {
      //emit close!
      this.$emit("close");
    },
    addContact() {
      this.tempContactGroup.Contacts.push({
        Id: this.getNextNewContactId(),
        Name: "",
        Email: "",
        Telephone: "",
        Role: ""
      });
    },
    getNextNewContactId() {
      this.newContactId = this.newContactId - 1; //increment down the new contact id
      return this.newContactId;
    },
    removeContact(c) {
      this.tempContactGroup.Contacts.splice(
        this.tempContactGroup.Contacts.indexOf(c),
        1
      );

      if (c.Name)
        this.$toast.open(c.Name + " - will be removed once dialog is saved");
    },
    removeContactIfEmpty(c) {
      if (
        c.Name === "" &&
        c.Email === "" &&
        c.Telephone === "" &&
        c.Role === ""
      ) {
        this.removeContact(c);
      }
    },
    onPasteContacts(evt) {
      var pastedValue = evt.clipboardData.getData("text");
      console.log(pastedValue);

      this.$toast.open("Pasting values");
      this.convertPasteToContactJsonAndAdd(pastedValue);

      return true;
    },
    convertPasteToContactJsonAndAdd(text) {
      var rowsOfPastedValue = text.split("\n"); //split by line break
      for (var i = 0; i < rowsOfPastedValue.length; i++) {
        var pastedRow = rowsOfPastedValue[i];
        var pastedValues = pastedRow.split("\t"); //split by tab

        this.tempContactGroup.Contacts.push({
          Id: this.getNextNewContactId(),
          Name: pastedValues[0] ? pastedValues[0] : "",
          Email: pastedValues[1] ? pastedValues[1] : "",
          Telephone: pastedValues[2] ? pastedValues[2] : "",
          Role: pastedValues[3] ? pastedValues[3] : ""
        });
      }

      this.removeEmptyContacts();
    },
    removeEmptyContacts() {
      for (var i = this.tempContactGroup.Contacts.length - 1; i >= 0; i--) {
        this.removeContactIfEmpty(this.tempContactGroup.Contacts[i]);
      }
    },
    saveChanges() {
      var self = this;
      this.isLoading = true;
      var url = this.APIUrl + "/contact-group/";
      //save changes
      this.axios
        .post(url, this.tempContactGroup)
        .then(response => {
          var isNew = this.tempContactGroup.Id == null;
          this.tempContactGroup = response.data;
          this.isLoading = false;
          //show toast!
          this.$toast.open(
            "Successfully " +
              (isNew ? "updated" : "created") +
              " <b>'" +
              this.tempContactGroup.Name +
              "'</b>"
          );
          //emit changes back
          if (isNew) this.$emit("add", response.data);
          else this.$emit("close");
        })
        .catch(function(re) {
          self.isLoading = false;
          self.catchError(re);
        });
    }
  },
  computed: {
    isNewContactGroup() {
      return this.tempContactGroup.Id == null;
    }
  }
};
</script>
